import './App.css';
import {BrowserRouter} from "react-router-dom";
import React from "react";
import NavRoutes from "./NavRoutes";
import Navbar from "./component/NavBar/Navbar";
import Footer from "./component/Footer/Footer";

import {AuthProvider} from "./context/AuthContext";

function App() {

    return (
        <div>
            <AuthProvider>
                <BrowserRouter>
                    <Navbar/>
                    <NavRoutes/>
                    <Footer/>
                </BrowserRouter>
            </AuthProvider>
        </div>
    );
}

export default App;
