import React, {useContext, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import {auth} from "../../firebase.config";

export default function Photo() {


    const navigate = useNavigate()
    const {isConnected} = useContext(AuthContext)

    useEffect(() => {

        console.log(auth.currentUser)

        if (!isConnected)
            navigate("/login")
    }, []);


    return (
        <div className={"contentLogin"}>
            <h2 className={"titrePrimaire"}>Accés aux photos</h2>

            <p style={{color: "white", marginBottom: 20, fontSize: 20}}>Lien vers les photos : </p>
            {

                isConnected ? <a style={{marginBottom: 20, fontSize: 20}} href="https://drive.google.com/drive/u/0/folders/1bMR74TG0eKqLg243UnR6MEIP2EQ8VRL8">https://drive.google.com/drive/u/0/folders/1bMR74TG0eKqLg243UnR6MEIP2EQ8VRL8</a> : ""
            }
        </div>
    )
}