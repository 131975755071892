import React from "react";
import './footer.css'
import fr from '../../img/france_flag.png'

export default function Footer() {
    return (
        <div className={"container_footer"}>
            <p>© 2022 - 2022 - Scout groupe 11eme Toulouse - All Rights Reserved.</p>
            <img src={fr} alt="Drapeau français"/>
        </div>
    )
}