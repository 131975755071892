import React, {useEffect, useState} from "react";
import cerf from "../../../img/cerf.jpg";
import chamois from "../../../img/chamois.jpg";
import gerfaut from "../../../img/gerfaut.png";
import {FiEye} from "react-icons/fi";
import {Dialog} from "@mui/material";
import {api, onError} from "../../../utils/Utils";
import {MethodsNames} from "../../../models/MethodsNames";
import {Patrouille} from "../../../models/Patrouille";
import {UserPatrouille} from "../../../models/UserPatrouille";

export default function Patrouilles() {

    const [pat, setPat] = useState<Array<Patrouille>>([])

    const img = [cerf, chamois, gerfaut]


    useEffect(() => {

        api(MethodsNames.GET, "/patrouille/findPatrouilleScout", undefined, onGetPatrouilleSuccess, onError)

        // fetch("https://scout-tls.gitlab.io/scout-data/data_pat.json")
        //     .then(response => {
        //         return response.json()
        //     }).then(data => {
        //
        //     let tmp = {
        //         "pat": data
        //     }
        //     setPat(tmp)
        //
        // })
    }, [])

    const onGetPatrouilleSuccess = (data: Array<Patrouille>) => {

        setPat(data)
    }

    return (<div className={"thridContent"} id={"pat"}>
            <h1 className={"titre"}>Patrouilles</h1>
            <div className={"allPat"}>
                {
                    pat.map((e: Patrouille, count: number) => {
                        return (
                            <div key={count} className={"patrouille"}>
                                <div className={"img_partrouille"}>
                                    <img src={String(img[count])} alt="image patrouille"/>
                                    <p className={"cri"}>{e.cri}</p>
                                </div>
                                <div className={"nom_patrouille"}>
                                    <h1>{e.nom}</h1>
                                    {/*<Cp data={e.cp}/>*/}
                                </div>
                                <div className={"patron"}>
                                    <h1>{e.saint}</h1>
                                </div>
                                <div className={"bande"}>
                                    {/*<div style={{backgroundColor: e.color[0]}}/>*/}
                                    {/*<div style={{backgroundColor: e.color[1]}}/>*/}
                                </div>
                                <div className={"desc"}>
                                    <p>{e.couleur1} / {e.couleur2}</p>
                                </div>
                                <div className={"patrouillards"}>
                                    {
                                        e.userPatrouilles
                                            .sort((a, b) => a.role > b.role ? -1 : 1)
                                            .map((e: UserPatrouille, count: number) => {
                                                return (
                                                    <div key={count} className={count === 0 ? "cp" : "autre"}>
                                                        <h2>{e.user.prenom} {e.user.nom.charAt(0)}</h2>
                                                    </div>
                                                )
                                            })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>

    )
}

const Cp = (props: any) => {

    const [open, setOpen] = useState(false)

    return (
        <div>
            <FiEye className={"icon_eye"} onClick={() => setOpen(true)}/>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <div style={{backgroundColor: "#191009"}}>
                    <h1 style={{margin: 20, color: "#e9d6cd"}}>Listes des CPs de la patrouille</h1>
                    {
                        props.data.map((e: any, count: number) => {
                            return (
                                <div style={{margin: 20, fontSize: 20, color: "#e9d6cd"}} key={count}>
                                    {e}
                                </div>
                            )
                        })
                    }
                </div>
            </Dialog>
        </div>
    )
}