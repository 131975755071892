import React from "react";
import "./style.scss"
import ordreCorbeau from '../../../img/LOrdreduCorbeau.png'
import templierDrapeau from '../../../img/templierDrapeau.webp'
import derniertemplier from '../../../img/derniertemplier.jpg'
import supreme from '../../../img/maitreSupreme.webp'
import veilleur from '../../../img/Veilleur.webp'
import templier from '../../../img/templierIcon.webp'

export default function Imaginaire() {

    const ARBRE_MECHANT: Arbre = {
        boss: {
            text: "Il est le chef mystérieux de l'Ordre du Corbeau. Personne ne connait son identité...",
            nom: "Le Maître Suprême",
            classN: "top",
            img: supreme
        },
        other: [
            {
                text: "Il est un maître de la construction au sein de l'ordre, spécialisé dans l’édification de forteresses et de passages secrets. On ne connaît ni son nom ni son origine, mais il est très réputé dans tout ce qu'il fait pour l'Ordre.",
                img: veilleur,
                classN: "bot",
                nom: "Veilleur-Bâtisseur"
            },
            {
                text: "Il est un maître des mots et des discours envoûtants, capable de rallier les foules. Inconnu de tous, il se cache derrière une identité changeante, usant de son charisme et de son talent d’expression pour manipuler les esprits.",
                img: veilleur,
                classN: "bot",
                nom: "Veilleur-Orateur"
            },
            {
                text: "Expert de la topographie et des cartes de l'ordre, chargé de tracer les routes secrètes et de repérer les lieux stratégiques. Son identité reste un mystère, mais il est reconnu pour ses cartes détaillées.",
                img: veilleur,
                classN: "bot",
                nom: "Veilleur-Cartographe"
            },
            {
                text: "La main implacable de l’ordre, chargé des missions les plus sombres : l'élimination des traîtres, l'exécution des ennemis, et l'interrogatoire des captifs. Sa véritable identité est inconnue, mais sa réputation inspire la crainte.",
                img: veilleur,
                classN: "bot",
                nom: "Veilleur-Exécuteur"
            },
        ]
    }
    const ARBRE_GENTIL: Arbre = {
        boss: {
            text: "Jacques de Molay est le chef des derniers Templiers.",
            nom: "Le Grand Maître",
            classN: "top",
            img: derniertemplier
        },
        other: [
            {
                text: "Guerrier passionné et impétueux, il mène les charges avec une épée flamboyante, symbolisant le zèle et la bravoure. Sa tunique est marquée d’une croix rouge plus large, évoquant sa détermination sans faille.",
                img: templier,
                classN: "bot",
                nom: "Templier-Corentin"
            },
            {
                text: "Un colosse calme, vêtu d'une armure lourde, toujours à l’avant-garde pour défendre les faibles. Son bouclier porte de nombreuses entailles, témoins des batailles qu'il a endurées pour protéger ses frères.",
                img: templier,
                classN: "bot",
                nom: "Templier-Antoine"
            },
            {
                text: "Fin stratège et érudit, il est souvent consulté pour ses connaissances en science et tactique militaire. Son casque porte des gravures anciennes, symboles de sa sagesse et de son dévouement à l’ordre.",
                img: templier,
                classN: "bot",
                nom: "Templier-Ambroise"
            },
            {
                text: "Rapide et discret, il se déplace en éclaireur, repérant les ennemis et cartographiant les terrains. Sa tunique légère et ses bottes de cuir souple permettent des mouvements silencieux et efficaces.",
                img: templier,
                classN: "bot",
                nom: "Templier-Roman"
            },
            {
                text: "Expert en cartographie et en navigation, il guide les expéditions templières sur terre et en mer. Avec une boussole toujours à portée de main et une carte usée accrochée à sa ceinture, il est connu pour son habileté à trouver des chemins sûrs et des passages secrets.",
                img: templier,
                classN: "bot",
                nom: "Templier-Constantin"
            },
        ]
    }

    return (<div className={"content_imaginaire"}>
        <div className="imaginaire">
            <h4>Il est conseillé d'ouvrir cette page sur un ordinateur !</h4>
            <h1>Imaginaire de l'année</h1>
            <p>Cette page est ici pour vous aider à suivre l'imaginaire de l'année...</p>

            <h2>Contexte</h2>
            <p>
                <table>
                    <tbody>
                    <tr>
                        <th>Quand ?</th>
                        <td>En <u><b>l'an 1347</b></u>, peu de temps après la dissolution des Templiers.</td>
                    </tr>
                    <tr>
                        <th>Qui sont les chefs ?</th>
                        <td>Les chefs jouent le rôle d'<u><b>anciens Templiers</b></u> toujours au service de leur noble
                            cause.
                        </td>
                    </tr>
                    <tr>
                        <th>Qui sont les scouts ?</th>
                        <td>Vous jouez le rôle de braves hommes qui souhaitent se <u><b>joindre au noble
                            combat</b></u> des
                            Templiers.
                        </td>
                    </tr>
                    <tr>
                        <th>Qui sont les enemis ?</th>
                        <td>L'ordre du Corbeau est une commanderie qui naquit après la dissolution des Templiers. Elle a
                            pour but
                            d'<u><b>éliminer les derniers Templiers et de retrouver les trésors cachés</b></u>.
                        </td>
                    </tr>
                    <tr>
                        <th>Pourquoi ?</th>
                        <td>Durant l'année l'Ordre du Corbeau a pour but de traquer et d'éliminer les derniers
                            Templiers. Ils ont aussi pour objectif de trouver les trésors bien connu des Templiers.
                            <br/><br/>
                            Les Templiers, eux, ont pour objectif de réunir les derniers Templiers et de constituer une
                            nouvelle armée pour lutter contre ce nouvel Ordre maléfique.
                        </td>
                    </tr>
                    </tbody>
                </table>
            </p>
            <p>L’imaginaire autour des Templiers et de l’Ordre du Corbeau mêle histoire, mystère et légende. Les
                Templiers, guerriers sacrés et moines dévoués, défendent la chrétienté au Moyen Âge tout en
                bâtissant
                une richesse et une influence qui suscitent admiration et méfiance. Leur dissolution brutale en
                1312, sous
                l'accusation d’hérésie et de complots, alimente des mythes persistants de trésors cachés et de
                secrets
                interdits. Parallèlement, l’énigmatique Ordre du Corbeau, opère dans l’ombre, gardant des savoirs
                anciens et manipulant les événements depuis les coulisses. Ensemble, ces deux ordres incarnent une
                quête
                éternelle de vérité, de pouvoir et de mystères non résolus, captivant l’imagination à travers les
                siècles.</p>


            <h1>Les templiers</h1>
            <p>L’Ordre des Templiers, connu officiellement sous le nom d’Ordre des Pauvres Chevaliers du Christ et
                du
                Temple
                de Salomon, est né au cœur des croisades, au début du XIIe siècle. <u><b>Fondé en 1119 par neuf
                    chevaliers</b></u> français dirigés par Hugues de Payns, cet ordre a vu le jour avec une mission
                claire : protéger les
                pèlerins
                chrétiens voyageant en Terre Sainte, sur les routes dangereuses menant à Jérusalem.</p>
            <p>Près de 200 ans après, sous l'impulsion de Philippe IV de France, jaloux de leur influence et
                désireux de
                s’emparer de leurs richesses, les Templiers furent accusés d'hérésie et de crimes infâmes. En
                quelques
                années, <u><b>l'ordre fut démantelé par l’Église</b></u>, et ses membres persécutés. En 1312, le pape
                Clément V
                prononça
                la dissolution de l'ordre, et Jacques de Molay, leur dernier Grand Maître, fut exécuté.</p>

            <h2>Origines et Symboliques</h2>
            <div className={"content_text_img"}>
                <p>La symbolique des Templiers est profondément ancrée dans l'histoire et la spiritualité
                    chrétienne,
                    mais
                    elle
                    transcende aussi ces frontières pour toucher l'imaginaire collectif. Leur emblème le plus
                    reconnaissable, <u><b>la croix rouge pattée sur fond blanc</b></u>, symbolise à la fois leur
                    engagement spirituel et leur mission
                    militaire : le blanc représente la pureté de leur foi et leur vœu de chasteté, tandis que le
                    rouge
                    incarne
                    le sacrifice, le sang versé pour défendre la chrétienté. En tant que chevaliers-moines, les
                    Templiers
                    alliaient la dévotion religieuse au courage guerrier, incarnant l'idéal du chevalier chrétien.
                    Leur
                    image de
                    soldats du Christ, combattant pour une cause divine, a fait d'eux des figures emblématiques du
                    Moyen
                    Âge,
                    entourées de mystères, de légendes et de spéculations sur leurs véritables motivations et leurs
                    trésors
                    cachés.</p>
                <img src={templierDrapeau} alt="Ordre des templiers"/>
            </div>

            <h2>Organisation et hiérarchie</h2>
            <div className={"content_text_img"}>
                <p>L'Ordre des Templiers est <u><b>organisé de manière rigoureuse</b></u>, avec une hiérarchie qui
                    reflète à la
                    fois
                    leur mission militaire et leur engagement religieux. À sa tête se trouve le <u><b>"Grand Maître"</b></u>,
                    élu
                    à vie
                    par le chapitre général de l'ordre, qui exerce une autorité absolue sur toutes les décisions
                    stratégiques et administratives, <u><b>son nom est Jacques de Molay</b></u>. Il est secondé par des
                    dignitaires que
                    l'on appelle des <u><b>"sergents"</b></u> qui servent d'auxiliaires armés et d'artisans. Cette hiérarchie
                    stricte
                    garantit une discipline rigoureuse et une efficacité militaire redoutable, tout en maintenant
                    une
                    cohésion autour de leurs valeurs et de leur mission sacrée.</p>
            </div>
            <Arbre arbre={ARBRE_GENTIL} classN={"customArbre"}/>

            <h1>L'ordre du corbeau</h1>
            <p>L’Ordre du Corbeau est <u><b>une confrérie secrète</b></u>, née des ombres après la dissolution de l'ordre des
                templiers. Fondé dans les profondeurs des forêts obscures d’Europe, cet ordre énigmatique rassemble
                des
                chevaliers et des érudits aux intentions ambiguës, liés par un serment de loyauté à une cause
                inconnue de
                tous sauf de ses membres les plus anciens. Ils ont pour <u><b>seul objectif et mission d'éliminer les
                    derniers
                    templiers et de retrouver le trésors des templiers</b></u>.</p>
            <h2>Origines et Symboliques</h2>
            <div className={"content_text_img"}>
                <p>L’Ordre du Corbeau tire son nom de l’oiseau noir qui, dans de nombreuses cultures, est <u><b>un
                    symbole
                    de
                    présages, de mort</b></u>, et de secrets cachés. Tel le corbeau, les membres de cet ordre opèrent en
                    silence
                    et dans l’obscurité, observant et manœuvrant dans l’ombre pour atteindre leurs objectifs. Leur
                    emblème,
                    un corbeau au vol menaçant, rappelle à tous leur vigilance et leur présence constante, même
                    lorsqu’ils
                    ne sont pas visibles.</p>
                <img src={ordreCorbeau} alt="Ordre des corbeaux"/>
            </div>


            <h2>Organisation et hiérarchie</h2>
            <p>L'Ordre du Corbeau est organisé en cellules dispersées à travers les terres françaises, chacune
                dirigée
                par un <u><b>"Veilleur"</b></u> , un maître des ombres connaissant les codes, les rites et les missions
                secrètes de
                l'ordre. Ils sont répartis selon une hiérarchie bien précise. À leur tête se trouve
                le <u><b>"Maître Suprême"</b></u> ,
                dont nous ne connaissons pas l'identité, mais nous savons qu'<u><b>il coordonne les opérations des
                    Veilleurs</b></u>.</p>
            <br/>
            <p>Voici tout ce que nous savons de l'ordre :</p>
            <Arbre arbre={ARBRE_MECHANT}/>

            <h2>Ce qu'il faut savoir</h2>
            <p>
                <ul>
                    <li>Ils ont une marque sur la main qui leur permet de se reconnaître.</li>
                </ul>
            </p>


        </div>
    </div>)
}

interface Personne {
    nom: string
    img: string
    classN: string
    text: string
}

interface Arbre {
    boss: Personne
    other: Array<Personne>
}

const Arbre = (props: { arbre: Arbre, classN?: string }) => {

    const Personne = (props: { personne: Personne }) => {
        return (
            <div className={"item_personne_arbre " + props.personne.classN}>
                <img src={props.personne.img} alt={props.personne.nom}/>
                <h3>{props.personne.nom}</h3>
                <p>{props.personne.text}</p>
            </div>
        )
    }

    return (
        <div className={"arbre " + props.classN}>
            <div className={"ligne_arbre"}>
                <Personne personne={props.arbre.boss}/>
            </div>
            <div className={"sep"}></div>
            <div className={"ligne_arbre"}>
                {
                    props.arbre.other.map((e, count) => <Personne key={count} personne={e}/>)
                }
            </div>
        </div>
    )
}

