import React from "react";

import './meute.css'

export default function Meute() {
    return (
        <div className={"container_meute"}>


            <div className={"firstContent"}>
                <h1>Meute</h1>
            </div>

            <p>Arrive bientôt...</p>


        </div>
    )
}