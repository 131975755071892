import React from "react";

export default function ChantTroupe() {

    return (
        <div className={"chant"} id={"chant"}>


            <h1 className={"titre"}>Chant de troupe</h1>
            <p className={"refrain"}>
                Le Lion secoue sa crinière<br/>
                Sous le ciel sanglant et or,<br/>
                Il mène la 11 vers la lumière<br/>
                Bercé par le chant du cor. <br/> <br/>
            </p>
            <p>
                De son puissant rugissement, <br/>
                Le Lion mène la marche,<br/>
                Portant fièrement le Baussant,<br/>
                À son approche l'ennemi se
                cache. <br/> <br/>

                Et surgit alors le Cerf<br/>
                Hors de sa forêt profonde,<br/>
                Noble et humble comme St Hubert<br/>
                Il s'applique à servir le monde. <br/> <br/>

                Dans la montagne solitaire, <br/>
                De roc en roc le Chamois bondit, <br/>
                Déchiré entre ciel et terre,<br/>
                Il garde la foi contre l'ennemi.<br/> <br/>

                Vif et libre comme l'air, <br/>
                Dans le ciel bleu le Gerfaut,<br/>
                S'élève loin de la sphère<br/>
                Il aspire à toujours plus haut. <br/> <br/>

                Fier, rapide et rusé,<br/>
                Le Renard au teint orangé,<br/>
                Meut de tanière en tanière,<br/>
                Pour conquérir monts et terres.<br/><br/>
            </p>
        </div>
    )

}