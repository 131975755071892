import Cookies from 'universal-cookie';
import {MethodsNames} from "../models/MethodsNames";

/**
 * Communication API
 * @param method GET, POST, PUT, DELETE
 * @param url
 * @param body
 * @param successCallback
 * @param errorCallback
 */
export const api = (
    method: MethodsNames,
    url: string,
    body: any,
    successCallback: any,
    errorCallback: any
) => {

    // Authorization: `Bearer ${token}`,
    fetch("http://localhost:8080" + url, {
        method: method,
        body: JSON.stringify(body),
        cache: "no-cache",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
        },
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            successCallback(data);
        })
        .catch((error) => {
            errorCallback(error);
        });
};

/**
 * Log error
 * @param data message error
 */
export const onError = (data: any) => {
    console.error(data);
};

const cookies: Cookies = new Cookies();

export const getToken = () => {
    let user_tmp = cookies.get("user");

    return user_tmp !== null ? user_tmp : false;
};