import React from "react";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'
import './style.scss'
import {GoLink} from "react-icons/go";
import {MdFormatListNumbered} from "react-icons/md";

export default function TableauImage() {

    const [image, setImage] = React.useState<string>("");

    const [lignes, setLignes] = React.useState<number>(5)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const selectedImage = event.target.files.item(0)
            if (selectedImage !== undefined && selectedImage !== null) {
                setImage(URL.createObjectURL(selectedImage));
            }
        }
    }

    const createPdf = () => {

        const doc = new jsPDF();

        const imgWidth = 30;
        const imgHeight = 30;
        const cellPadding = 1;

        const columns = 6; // Nombre de colonnes

        const tableData = Array(lignes).fill(
            Array(columns).fill({
                content: '',
                imgUrl: image
            })
        );

        autoTable(doc, {
            theme: "plain",
            body: tableData,
            bodyStyles: {minCellHeight: 30},
            columns: Array(columns).fill({header: '', dataKey: 'content'}),
            didDrawCell: (data: any) => {
                if (data.cell.section === 'body' && data.cell.raw.imgUrl) {
                    const img = new Image();
                    img.src = data.cell.raw.imgUrl;
                    doc.addImage(
                        img,
                        'JPEG',
                        data.cell.x + cellPadding,
                        data.cell.y + cellPadding,
                        imgWidth,
                        imgHeight
                    );
                }
            }
        });

        doc.save('table.pdf')
    }

    return (
        <div className={"container_generateur"}>

            <div className={"firstContent"}>
                <h1>Contact</h1>
            </div>

            <div className={"content_image_tab"}>
                <div className={"image_content"}>
                    <h2>Image</h2>
                    <p>Selectionner l'image que vous souhaitez</p>

                    <div>
                        <div className={"inputCustom"}>
                            <GoLink/>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                        <h3>Ou</h3>
                        <div className={"inputCustom"}>
                            <GoLink/>
                            <input
                                type="text"
                                onChange={(e) => setImage(e.target.value)}
                                placeholder={"Lien vers une image"}/>
                        </div>

                    </div>
                </div>

                <div>
                    <h2>Parametrage</h2>

                    <p>Selectionner le nombre de ligne</p>
                    <div className={"inputCustom"}>
                        <MdFormatListNumbered/>
                        <input type="number"
                               value={lignes}
                               onChange={event => setLignes(parseInt(event.target.value))}
                        />
                    </div>
                </div>

                <button className={"buttonCustom"} onClick={createPdf}>Génération</button>
            </div>

        </div>
    )
}