import React from "react";

import './docs.css'
import {Link} from "react-router-dom";
import camp from '../../img/icons/camp.svg'
import carto from '../../img/icons/carto.svg'
import fire from '../../img/icons/fire.svg'
import raid from '../../img/icons/raid.svg'
import morse from '../../img/icons/morse.svg'
import doctor from '../../img/icons/doctor.svg'

export default function Docs() {

    const LIST_ITEMS = [
        {
            "name": "Techniques de froissartage",
            "icon": camp,
            "link": "0"
        },
        {
            "name": "Secourisme",
            "icon": doctor,
            "link": "1"
        },
        {
            "name": "Technique de transmission",
            "icon": morse,
            "link": "2"
        },
        {
            "name": "Rapport de raid",
            "icon": raid,
            "link": "3"
        },
        {
            "name": "Techniques trappeurs",
            "icon": fire,
            "link": "4"
        },
        {
            "name": "Topographie",
            "icon": carto,
            "link": "5"
        }
    ]

    return (
        <div className={"container_docs"}>


            <div className={"firstContent"}>
                <h1>Documentation</h1>
            </div>


            <div className={"list_item"}>
                {
                    LIST_ITEMS.map((item, count) => {
                        return (
                            <Item key={count} name={item.name} link={item.link} icon={item.icon}/>
                        )
                    })
                }
            </div>


        </div>
    )
}

const Item = ({icon, name, link}: any) => {

    return (
        <div className={"item"}>
            <Link to={link}>
                <img src={icon} alt={name + " icon"}/>
                <h1>{name}</h1>
            </Link>
        </div>
    )
}