import React, {useEffect} from "react";
import './acceuil.css'
import Separator from "../Utils/Separator/Separator";

export default function Accueil() {


    useEffect(() => {
        window.addEventListener('scroll', function () {
            let text = document.getElementById('text');
            if (text !== null) {
                let value = this.window.scrollY;
                text.style.transform = "translateY(-" + value * 0.3 + "px)";

            }
        })
    }, [])

    return (<div className={"container_accueil"}>

        <div className={"top_acceuil"}>
            <div id={"text"}>
                <h1>Groupe XI ème Toulouse</h1>
            </div>
        </div>

        <div className={"second_acceuil"}>
            <div className={"citation"}>
                <p className={"quote quote_top"}>"</p>
                <p>
                    Soyez toujours prêts à vivre heureux et à mourir heureux. Soyez toujours fidèles à votre
                    Promesse scoute même quand vous aurez cessé d'être un enfant - et que Dieu vous aide à y
                    parvenir !
                </p>
                <p className={"quote quote_bot"}>"</p>
            </div>
            <div className={"img_baden"}>
                <div className={"imageBaden"}/>
                <p>Baden POWELL</p>
            </div>
        </div>

        <Separator/>

        <div className={"troisieme_acceuil"}>
            <p>
                Bienvenue sur le site de troupe de la XI ème Toulouse ! <br/><br/>

                Fondée en 1975, la XI ème Toulouse est un des groupes de scouts d'Europe les plus anciens de la province
                Haut Languedoc, son foulard Rouge Sang et Jaune Or rappelle les couleurs de la région Occitane mais
                aussi la passion et la royauté du christ. <br/><br/>


                Composée d'une meute de 24 louveteaux (8-12 ans) et d'une troupe d'une vingtaine de scouts (12-17 ans),
                la XI ème Toulouse suit la méthode scoute inventée par Robert Baden-Powell et enrichie notamment par le
                père Jacques Sevin. Elle vise à l'épanouissement des enfants et des adolescents par le jeu et l'aventure
                dans la nature et la prise de responsabilités au sein du groupe. Les activités organisées ont pour but
                d'aider le jeune à s'épanouir dans cinq domaines appelés traditionnellement « les cinq buts du scoutisme
                » : la santé, la formation du caractère, le sens du concret, le sens du service, le sens de Dieu.

            </p>
            {/*<div>*/}
            {/*    <GiFleurDeLys className={"icon_acc"}/>*/}
            {/*    <h2>Scout d'Europe</h2>*/}
            {/*    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio doloremque doloribus, facere*/}
            {/*        fugit itaque labore. Alias amet consectetur eaque id, laudantium, perferendis porro praesentium*/}
            {/*        quis quo temporibus velit voluptates voluptatum?</p>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*    <GiBrain className={"icon_acc"}/>*/}
            {/*    <h2>Valeurs et savoirs</h2>*/}
            {/*    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio doloremque doloribus, facere*/}
            {/*        fugit itaque labore. Alias amet consectetur eaque id, laudantium, perferendis porro praesentium*/}
            {/*        quis quo temporibus velit voluptates voluptatum?</p>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*    <GiIronCross className={"icon_acc"}/>*/}
            {/*    <h2>La religion</h2>*/}
            {/*    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio doloremque doloribus, facere*/}
            {/*        fugit itaque labore. Alias amet consectetur eaque id, laudantium, perferendis porro praesentium*/}
            {/*        quis quo temporibus velit voluptates voluptatum?</p>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*    <CgBoy className={"icon_acc"}/>*/}
            {/*    <h2>Jeune Hommes</h2>*/}
            {/*    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio doloremque doloribus, facere*/}
            {/*        fugit itaque labore. Alias amet consectetur eaque id, laudantium, perferendis porro praesentium*/}
            {/*        quis quo temporibus velit voluptates voluptatum?</p>*/}
            {/*</div>*/}

        </div>

    </div>)
}