import React, {useEffect, useState} from "react";

import './docs.css'
import {Link, useParams} from "react-router-dom";

import camp from "./pdf/Brevet Majeur Camps.pdf"
import codage from "./pdf/Guide de codage.pdf"
import topo from "./pdf/Le croquis topographique.pdf"
import raid from "./pdf/Le rapport de raid.pdf"
import secour from "./pdf/Secourisme.pdf"
import transmission from "./pdf/Techniques Transmission.pdf"
import trappeur from "./pdf/Techniques trappeurs.pdf"
import icon_camp from '../../img/icons/camp.svg'
import icon_carto from '../../img/icons/carto.svg'
import icon_fire from '../../img/icons/fire.svg'
import icon_raid from '../../img/icons/raid.svg'
import icon_morse from '../../img/icons/morse.svg'
import icon_doctor from '../../img/icons/doctor.svg'
import {FiChevronLeft, FiChevronRight} from "react-icons/fi";


export default function Detail() {

    let {id} = useParams<string>()

    let LIST_ITEMS: any = {
        0: {
            "name": "Techniques de froissartage",
            "icon": icon_camp,
            "pdf": [
                camp
            ]
        },
        1: {
            "name": "Secourisme",
            "icon": icon_doctor,
            "pdf": [
                secour
            ]
        },
        2: {
            "name": "Technique de transmission",
            "icon": icon_morse,
            "pdf": [
                codage,
                transmission
            ]
        },
        3: {
            "name": "Rapport de raid",
            "icon": icon_raid,
            "pdf": [
                raid
            ]
        },
        4: {
            "name": "Techniques trappeurs",
            "icon": icon_fire,
            "pdf": [
                trappeur
            ]
        },
        5: {
            "name": "Topographie",
            "icon": icon_carto,
            "pdf": [
                topo
            ]
        },
    }

    const [item, setItem] = useState(LIST_ITEMS[0])

    useEffect(() => {
        setItem(LIST_ITEMS[getId()])

    }, []);

    // Number.prototype.mod = function(n) {
    //     return (( this % n ) + n ) % n;
    // };

    const mod = (n: number, m: number): number => {
        return ((n % m) + m) % m;
    }

    const getId = (): number => {
        return id === undefined ? 1 : parseInt(id);
    }

    return (
        <div className={"container_docs"}>


            <div className={"firstContent"}>
                <h1>{item.name}</h1>
            </div>

            <div className={"icon"}>
                <img src={item.icon} alt=""/>
            </div>
            <div className={"list_pdf"}>
                {
                    item.pdf.map((pdf: any, count: any) => {
                        return (
                            <iframe key={count} src={pdf} width={"90%"} height={700}/>
                        )
                    })
                }
            </div>


            <div className={"navigation"}>
                <Link to={"/docs/" + (mod((getId() - 1), Object.keys(LIST_ITEMS).length))}>
                    <FiChevronLeft className={"svg_r"}/>
                    <h2>{LIST_ITEMS[mod((getId() - 1), Object.keys(LIST_ITEMS).length)].name}</h2>
                    <img src={LIST_ITEMS[mod((getId() - 1), Object.keys(LIST_ITEMS).length)].icon} alt=""/>
                </Link>
                <Link to={"/docs/" + mod((getId() + 1), Object.keys(LIST_ITEMS).length)}>
                    <img src={LIST_ITEMS[mod((getId() + 1), Object.keys(LIST_ITEMS).length)].icon} alt=""/>
                    <h2>{LIST_ITEMS[mod((getId() + 1), Object.keys(LIST_ITEMS).length)].name}</h2>
                    <FiChevronRight className={"svg_l"}/>
                </Link>
            </div>
        </div>
    )
}