import {createContext, useEffect, useState} from "react";

export interface AuthContextType {
    isConnected: boolean;
    isAdmin: boolean;
    authChecker: (response: any) => void;
}

const AuthContext = createContext<AuthContextType>({
    isConnected: false,
    isAdmin: false,
    authChecker: () => {
    }
});

export const AuthProvider = ({children}: any) => {

    const [isConnected, setIsConnected] = useState(false)

    const [isAdmin, setIsAdmin] = useState(false)

    useEffect(() => {

        if (localStorage.getItem("isConnected") === "true") {

        }

    }, []);

    const authChecker = (response: any): void => {

        if (response === undefined) {
            setIsConnected(false)
            setIsAdmin(false)
        } else {
            if (response.email !== undefined && response.email === "admin@gmail.com")
                setIsAdmin(true)
            else
                setIsAdmin(false)

            setIsConnected(true)
        }
    }


    return (
        <AuthContext.Provider value={{isConnected, isAdmin, authChecker}}>
            {children}
        </AuthContext.Provider>
    )

}

export default AuthContext;