import React, {useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import {signInWithEmailAndPassword} from 'firebase/auth';
import {auth} from '../../firebase.config'
import AuthContext, {AuthContextType} from "../../context/AuthContext";
import {FiLock} from "react-icons/fi";
import './login.css'

export default function Login() {

    const {authChecker, isConnected, isAdmin} = useContext<AuthContextType>(AuthContext)

    const navigate = useNavigate();

    const [password, setPassword] = useState('');
    const [isAdminState, setIsAdminState] = useState(false)
    const [isError, setIsError] = useState(false)

    const onLogIn = (e: any) => {
        e.preventDefault()
        signInWithEmailAndPassword(auth, isAdminState ? "admin@gmail.com" : "user@gmail.com", password)
            .then((userCredential) => {
                const user = userCredential.user;
                authChecker(user)
                setIsError(false)
                localStorage.setItem("isConnected", "true")
            })
            .catch(error => {
                authChecker(undefined)
                setIsError(true)
            })
    }

    const onLogOut = () => {
        authChecker(undefined)
    }

    return (
        <div className={"contentLogin"}>

            <h2 className={"titrePrimaire"}>Interface de connexion</h2>

            {
                isConnected ? (
                    <div>
                        <button
                            className={"connectButton"}
                            type="submit"
                            onClick={onLogOut}
                        >
                            Me déconnecter
                        </button>
                    </div>
                ) : (
                    <div>

                        {
                            isError ?
                                <p className={"errorText"}>Attention il y a une erreur dans le mot de passe !</p> : ""
                        }

                        {/*<div className={"isAdmin"}>*/}
                        {/*    <input*/}
                        {/*        id={"admin"}*/}
                        {/*        type={"checkbox"}*/}
                        {/*        value={isAdmin}*/}
                        {/*        onChange={(e) => setIsAdmin(e.target.value)}*/}
                        {/*    />*/}
                        {/*    <label htmlFor={"admin"}>Administrateur ?</label>*/}
                        {/*</div>*/}

                        <div className={"inputCustom"}>
                            <FiLock/>
                            <input
                                name={"password"}
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Entrez le mot de passe..."
                            />
                        </div>

                        <button
                            className={"buttonCustom"}
                            type="submit"
                            onClick={onLogIn}
                        >
                            Me connecter
                        </button>

                    </div>
                )
            }
        </div>)
}